@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    color: #818181 !important;
}

a:hover {
    color: #11365C !important;
}

.ant-row {
    margin-left: -15px;
    margin-right: -15px;
}

.ant-row .ant-row {
    margin: 0 !important;
}

.ant-col {
    padding: 15px;
}

.layout {
    background-color: white !important;
    display: flex;
    max-width: 100%;
}

.ant-layout-header .ant-menu {
    line-height: 64px !important;
}

.HeaderWrap {
    display:flex;
    justify-content: center;
    border-bottom: 1px solid #E1E1E1;
    background-color: #FFF;
    z-index: 100;
}

.HeaderArea {
    z-index: 100 !important;
    height: 54px !important;
    padding: 0 0 66px 0 !important;
    margin: 0 !important;
    background-color: #FFF !important;
    border: none !important;
    position: relative !important;
    display: flex !important;
}

.HeaderLogo {
    flex: auto;
}

.HeaderLogo img {
    height: 47px;
    width: auto;
}

.MenuArea {
    position: relative;
    background-color: transparent !important;
    border: none !important;
}

.MenuItem {
    font-family: Georgia, serif;
    font-size: 16px;
    color: #00365F;
}

.MenuItem:before {
    display: block;
    content: "";
    width: 1px;
    height: 15px;
    background-color: #b7b7b7;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 999;
}

.MenuItem:nth-child(2):before {
    display: none;
}

.menuDropdown ul li a:hover, .menuDropdown ul li span:hover {
    background-color: #ffffff;
    color: #11365c !important;
}

.menuDropdown {
    min-width: 280px !important;
}

.menuDropdown ul {
    margin-top: 20px !important;
}

.menuDropdown ul li {
    background-color: #ffffff !important;
    padding: 0;
}

.menuDropdown ul li a, .menuDropdown ul li span {
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal !important;
    font-size: 15px;
    line-height: 22px;
    padding: 15px 0 !important;
    margin: 0 20px !important;
    border-bottom: 1px solid #F2F2F2;
    color: #6E6E77 !important;
    display: block;
}

.menuDropdown ul li:last-child a, .menuDropdown ul li:last-child span {
    border: 0 !important;
}

.headerIcons {
    padding: 0 0 0 30px;
    margin-left: 30px;
    display: flex;
    justify-content: flex-end;
}

.headerIcons .icon {
    margin: 5px 10px;
    cursor: pointer;
}

.ant-menu-horizontal {
    white-space: inherit !important;
}

.main {
    box-sizing: border-box;
    width: 100vw;
    margin-right: auto;
    min-height: calc(100vh - 180px - 53px) !important;
    max-width: 1440px;
    margin-left: auto;
}

.leftColumn {
    text-align: left !important;
}

.rightColumn {
    text-align: right !important;
}

.sideNav {
    width: 100%;
    border-right: 1px solid rgba(59, 59, 59, 0.12);;
}

.loginLogo {
    width: auto !important;
    height: auto !important;
}

.burgerIcon {
    margin-top: 3px;
    display: block;
}

.pdp {
    margin: 40px 0;
}

.sideNav .ant-menu-item > a {
    font-style: normal;
    font-weight: normal;
    font-size: 15px !important;
}

.sideNav .ant-menu-item-selected > a, .sideNav .ant-menu-item-selected > a:hover, .sideNav .ant-menu-item > a:hover {
    color: #11365C !important;
}

.sideNav .ant-menu-item-active {
    background-color: #e6ebef59 !important;
}

.sideNav .ant-menu-item-selected {
    background-color: #F3F9FE !important;
}

.footer {
    width: 100%;
    text-align: center;
    background-color: #F2F2F2 !important;
    padding: 10px 80px !important;
}

.ant-carousel .slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden;
    max-height: 100%;
}

.ant-carousel .slick-prev, .ant-carousel .slick-next {
    width: 0;
    height: 0;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-top: 15px solid  #11365C !important;
}

.ant-carousel .slick-prev {
    transform: rotate(90deg);
}

.ant-carousel .slick-next {
    transform: rotate(-90deg);
}

.ant-carousel .slick-dots li button {
    background-color: #11365C !important;
}

.ant-modal.loginModal {
    max-width: 450px;
}

.ant-col {
    padding: 10px;
}

.ant-form .ant-col {
    padding: 0;
}

.ant-row.ant-form-item {
    margin-bottom: 10px !important;
}

.ant-row.ant-form-item.checkBox {
    margin-bottom: 0px !important;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
}

.ant-col.ant-form-item-label {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #353C43;
    padding: 0 !important;
}

.ant-form-large .ant-form-item-label > label {
    height: 30px !important;
    font-family: Georgia;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #353C43;
}

.ant-form-item-label > label.ant-form-item-required::before {
    font-size: 20px !important;
}

.ant-input {
    padding: 10px !important;
    border-radius: 4px !important;
    margin-bottom: 16px !important;
}

.ant-input-affix-wrapper input {
    margin: 0 !important;
}

.ant-input-number {
    border: 1px solid #D9D9D9 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
}

.ant-input-number-input {
    font-family: Noto Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #222222 !important;
}

.ant-input-affix-wrapper {
    max-height: inherit !important;
    padding: 0 10px !important;
}

.ant-form-item {
    margin: auto !important;
}

.ant-input-number-handler-wrap {
    position: absolute !important;
    top: 0;
    right: 0;
    width: 22px;
    height: 100%;
    background: #fff;
    border-left: 1px solid #d9d9d9;
    border-radius: 0 2px 2px 0;
    opacity: 0;
    -webkit-transition: opacity 0.24s linear 0.1s;
    transition: opacity 0.24s linear 0.1s;
    display: block !important;
    z-index: 999;
}

.ant-input-number-handler {
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
    margin-top: inherit !important;
}

.ant-input-number-handler span {
    align-items: center !important;
    justify-content: center !important;
}

.ant-input-number-handler-up-inner, .ant-input-number-handler-down-inner {
    position: relative !important;
    top: inherit !important;
    right: inherit !important;
    margin: auto !important;
    display: inherit !important;
}

.ant-select-selection-item, .ant-select-item-option-content {
    font-family: Noto Sans !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    color: #595959 !important;
}

.ant-form-item .ant-select {
    width: 100% !important;
}

.ant-btn {
    font-family: Noto Sans, serif;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    align-items: center !important;
    text-align: center !important;
    border-radius: 4px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    justify-content: center !important;
    height: 40px !important;
}

.ant-btn.mad-small {
    height: 30px !important;
    font-size: 14px !important;
}

.ant-btn-primary, .ant-btn-primary span {
    color: #FFFFFF !important;
    background: #11365C !important;
}

.ant-btn-primary[disabled] {
    color: #9A9DA0 !important;
}

.ant-collapse-header {
    font-family: Georgia;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px !important;
    color: #222222 !important;
    padding-left: 0 !important;
}

.ant-collapse-content-box {
    padding-top: 0 !important;
}

.ant-collapse-content {
    border: 0 !important;
}

.ant-table-thead > tr > th {
    font-family: Noto Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 20px !important;
    color: #9A9DA0 !important;
    background-color: #ffffff !important;
    border-top: 1px solid #F2F2F2 !important;
    padding: 4px 12px 4px 0 !important;
}

.ant-table-tbody > tr > td {
    font-family: Noto Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 22px !important;
    align-items: center !important;
    color: #222222 !important;
    padding: 12px 0 12px 0 !important;
}

/*.ant-table-tbody > tr:hover > td {*/
/*    background-color: #FFFFFF !important;*/
/*}*/

.ant-table-tbody > tr > td:nth-child(3), .ant-table-thead > tr > th:nth-child(3) {
    text-align: right;
    padding-right: 4% !important;
}

.ant-table-tbody > tr > td:nth-child(4), .ant-table-thead > tr > th:nth-child(4) {
    width: 25%;
}

.ant-table-container table > thead > tr th:last-child, .ant-table-container table > tbody > tr td:last-child {
    text-align: right;
    padding-right: 0 !important;
}

.ant-table-tbody > tr > td button {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    text-align: right;
    color: #0A6CBD;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    margin-right: 9px;
    padding-right: 9px;
    border-right: 1px solid #F2F2F2;
}

.ant-table-tbody > tr > td button:last-child {
    margin-right: 0;
    padding-right: 0;
    border: 0;
}

.ant-table-tbody > tr > td button.payConfirm {
    border: none;
}

.ant-table-tbody > tr > td a:last-child button {
    border-right: 0;
}

.ant-table-tbody > tr > td button:focus {
    outline: 0 !important;
}

.ant-table-tbody > tr > td > div > span {
    display: none;
}

.expandIcon {
    font-family: Noto Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    text-align: right !important;
    color: #0A6CBD !important;
}

.expandIcon.open {
    color: #6E6E77 !important;
}

.searchBar {
    overflow: hidden;
    padding: 0 !important;
}

.searchBar.open {
    width: 200px;
    opacity: 1;
    padding-left: 5px !important;
}

.searchBar.closed {
    width: 0;
    opacity: 0;
}

.ant-modal.addressModal .ant-modal-content {
    height: 100%;
}

.ant-modal.loginModal .ant-modal-body, .ant-modal.addressModal .ant-modal-body {
    height: 100%;
    overflow: auto;
}

.ant-modal.loginModal .ant-modal-footer {
    background: #F7FAFB;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    padding: 20px 15px;
    display: none;
    text-align: center;
    width: 100%;
}

.ant-dropdown-menu-item button {
    background-color: transparent;
    text-transform: capitalize;
    font-family: Noto Sans;
    font-size: 14px;
    color: #353C43;
}

.ant-collapse {
    margin-top: -1px !important;
    border: 0 !important;
}

.ant-collapse, .ant-collapse-borderless {
    background-color: white !important;
}

.ant-collapse-content-box {
    padding: 0px 0px 20px 0px !important;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 0 !important;
}

.ant-collapse > .ant-collapse-item {
    border-top: 1px solid #e7e7e7;
    border-bottom: 0 !important;
}

.ant-drawer-body {
    padding: 20px 16px !important;
}

.nav-menu {
    border-bottom: 0 !important;
}

.myAccount {
    margin-top: 24px;
}

.myAccount > .ant-row > div:last-child {
    padding-bottom: 106px;
    padding-left: 25px;
}

.ant-menu-inline {
    width: 100%;
    height: 100%;
}

.ant-tag span {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #595959;
}

.ant-modal.passwordModal {
    width: 404px !important;
    height: 500px !important;
}

.ant-modal.passwordModal .ant-modal-body {
    max-width: 404px;
    margin: auto;
    padding: 50px;
}

/*Checkout Styling*/
.checkoutStepHeader {
    display: flex;
    justify-content: center;
    margin: 21px 0 0 0;
    padding: 0 0 23px 0;
    border-bottom: 1px solid #E1E1E1;
}

.checkoutStepHeader >div {
    width: 585px;
}

.ant-input-search:not(.ant-input-search-enter-button) {
    padding-right: 0 !important;
}

.ant-picker-dropdown {
    font-family: Noto Sans !important;
}

.page-main {
    margin-bottom: 50px;
}

.cookieBanner {
    background-color: #353C43 !important;
    text-align: center;
    min-height: 40px;
    padding: 10px 50px !important;
}

.cookieBanner span {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    text-align: center !important;
    color: #FFFFFF !important;
    justify-content: center;
}

.cookieBanner span a {
    padding: 0 5px;
    color: #FFFFFF !important;
    text-decoration: underline;
}

.add-to-home-screen {
    position: fixed;
    z-index: 9990;
    text-align: right;
    right: 85px;
    bottom: 160px;
}

.add-to-home-screen-btn {
    border: 1px solid #ffffff !important;
}

@media (max-width: 1520px) {
    .main {
        padding: 0 80px;
    }
    .HeaderArea {
        padding: 0 80px 60px 80px !important;
    }
}

@media (max-width: 900px) {
    .main {
        padding: 0 50px;
    }
}

@media (max-width: 767px) {
    .main {
        padding: 0 18px;
    }
    .ant-row {
        margin-left: 0px;
        margin-right: 0px;
    }
    .myAccount > .ant-row > div:last-child {
        padding: 0;
    }
    .ant-table-thead {
        display: none;
    }
    .ant-table-tbody > tr {
        margin-bottom: 16px !important;
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 20px !important;
        display: block;
    }
    .ant-table-tbody > tr > td {
        border-bottom: 0 !important;
        width: 100% !important;
        display: block;
        padding: 0 !important;
        margin: 0 !important;
        text-align: left !important;
        color: #595959 !important;
    }
    .ant-table-tbody > tr > td:nth-child(4) {
        padding-left: 0 !important;
    }
    .ant-table-tbody > tr > td:last-child {
        margin-top: 20px !important;
    }
    .ant-table-tbody > tr > td > div > span {
        display: inline-block;
    }
    .ant-table-tbody > tr > td > div {
        font-size: 16px;
        line-height: 24px;
        color: #222222;
        margin-bottom: 4px;
    }
    .cookieBanner {
        position: fixed !important;
        bottom: 0;
        z-index: 9990;
        padding: 10px 30px 10px 15px !important;
    }
}

@media (max-width: 480px) {
    .checkoutStepHeader .ant-steps-horizontal.ant-steps-label-horizontal {
        flex-direction: row;
    }
    .checkoutStepHeader .ant-steps-item-tail {
        display: none;
        visibility: hidden;
        height: 0 !important;
        width: 0 !important;
    }
    .checkoutStepHeader {
        padding: 0;
        height: 51px;
    }
    .checkoutStepHeader >div {
        width: 100%;
    }
    .add-to-home-screen {
        right: inherit;
        width: inherit;
        text-align: center;
    }
}

@media (max-width: 380px) {
    .checkoutStepHeader {
        display: flex;
        align-items: center;
    }

    .checkoutStepHeader >div {
        width: 100%;
        margin-left: 60px;
    }

    .checkoutStepHeader .ant-steps-item {
        margin-right: auto !important;
    }

    .checkoutStepHeader .ant-steps-item-title {
        display: none;
        visibility: hidden;
    }

    .checkoutStepHeader .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
        margin: 0;
    }

    .checkoutStepHeader .ant-steps-item-container {
        width: 25px !important;
    }

    .checkoutStepHeader .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
        flex: 0;
    }
}

@media (max-width: 640px) {
    .block-promo {
        margin-bottom: 16px;
    }

    .block-promo:hover {
        text-decoration: none;
    }
    .HeaderArea {
        padding: 0 20px !important;
    }
    .headerIcons .icon {
        margin-left: 15px !important;
        margin-right: 0;
    }
    .searchBar {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        width: 100% !important;
        max-height: inherit !important;
        border: 5px solid #F3F3F3 !important;
    }
    .footer {
        padding: 0 20px !important;
    }
}

@media (max-width: 430px) {
    .HeaderLogo {
        flex: inherit;
    }
    .headerIcons {
        flex-grow: 1;
        border: 0;
        text-align: right;
    }
    .ant-modal.loginModal, .ant-modal.addressModal {
        max-width: 100%;
        top: 0;
        margin: 0;
        bottom: 0;
        position: fixed;
        padding: 0;
    }
    .ant-modal.loginModal .ant-modal-content, .ant-modal.addressModal .ant-modal-content.  {
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100% !important;
    }
    .ant-modal-content {
        height: 100%;
    }
    .ant-modal.loginModal .ant-modal-footer {
        display: block;
    }
}

.open, .close {
    transform-origin: top;
    animation-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
}

.open {
    animation: slideDown .2s linear;
}

.close {
    animation: slideUp .2s linear;
}

div.store-impersonator tr.ant-table-row {
    cursor: pointer !important;
}

@keyframes slideDown{
    from {
        transform: scaleY(0);
        opacity: 0;
    }
    to {
        transform: scaleY(1);
        opacity: 1;
    }
}

@keyframes slideUp{
    from {
        transform: scaleY(1);
        opacity: 1;
    }
    to {
        transform: scaleY(0);
        opacity: 0;
    }
}

/*.updatePulse {*/
/*    animation: updatePulse 3s;*/
/*}*/

/*@keyframes updatePulse {*/
/*    0% {*/
/*        background-color: #fff;*/
/*    }*/
/*    25% {*/
/*        background-color: #fc0;*/
/*    }*/
/*    100% {*/
/*        background-color: #fff;*/
/*    }*/
/*}*/
